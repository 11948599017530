import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const Bio = () => {
  return (
    <Evenenements>

    <ContainerFlex>
        <Event>
            <Image1>
                <StaticImage
                src="../assets/images/event2.jpg"
                width={300}
                alt="Bio"
                placeholder="traceBLURREDdSVG"
                quality={40}
                />
            </Image1>
            <ul>
                <li>2010-2012 : exposition collective / commissaire Lyz Friedrich / Mairie de Tournefeuille</li>
                <li>2013 : exposition collective / commissaire Quentin Jouret / Le BBB centre d’art</li>
                <li>2014 : performance en exposition collective / cours d’art Quentin Jauret et livre d’artiste</li>
                <li>2015 : exposition collective à la Chapelle des Carmélites / commissaire Quentin Jauret</li>
                <li>2016 : performance et exposition privée au restaurant Le Buok</li>
                <li>2017 : exposition collective ROUGE / commissaire Raphaël Larre</li>
                <li>2018 : exposition collective groupe 18/10 / commissaire Françoise Maisongrande / Maison Salvan</li>
                <li>2019 : performance collective "Parade des animaux" / commissaire Raphaël Larre / Musée d'Histoire Naturelle à Montauban</li>
                <li>2019 : exposition collective groupe 18/10 / avec Jürgen Schilling / Maison Salvan</li>
                <li>2022 : exposition collective à 4 mains "L’art dans la rue" / avec Laure Thibaud / Castelnau</li>
                <li>2023 : exposition collective à 4 mains "L’art dans la rue"/ avec Laure Thibaud / Castelnau</li>
                <li>2023 Sept/Oct : exposition collective / Place aux Arts Cugnaux</li>
                <li>2023 Août : exposition Lesalonreçoit</li>
            </ul>
            <Image2>
                <StaticImage
                src="../assets/images/event3.jpg"
                width={300}
                alt="Bio"
                placeholder="traceBLURREDdSVG"
                quality={40}
                />
        </Image2>
        </Event>
        </ContainerFlex>
    </Evenenements>
  )
}

export default Bio

const Evenenements = styled.div`

  `
  const ContainerFlex = styled.div`
  margin: 0rem ;
  color: rgba(169, 73, 69, 0.636);
  background-color: #404640;  
      @media screen and (max-width: 768px) {
        margin: 0rem ;
        margin-top: 0rem ;
        background-color: white;  
  `
const Image1 = styled.div`
  padding: 3rem;
  padding-left: 0rem;
  text-align: left;
  heigth: auto;
      @media screen and (max-width: 768px) {
        padding: 0rem;
        margin: 0rem;
        padding-bottom: 2rem;
         text-align: center;
      }
`
const Image2 = styled.div`
  padding: 3rem;
  padding-right: 0rem;
  text-align: right;
  heigth: auto;
      @media screen and (max-width: 768px) {
        margin: 0rem;
        padding: 0rem;
        text-align: center;
        padding-bottom: 2rem;
        padding-top: 2rem;
      }
`
const Event = styled.div`
margin-left: 15rem;
margin-right: 15rem;
  @media screen and (max-width: 768px) {
    margin-left: 0rem;
    margin-right: 0rem;
    }
    li {
      font-size: 0,5rem;
      font-weight: bold;
      color: #ab9668;
      text-align:left;
      }
      @media screen and (max-width: 768px) {
        padding: 1rem;
        li {
          margin-left: 1rem;
          margin-top: 1rem;
          }
            }
          
`