import * as React from "react"
import Layout from "../components/layout"
import Event2 from "../components/Event-2"

const Event2Page = () => (
  <Layout>
    <Event2 />
  </Layout>
)

export default Event2Page